import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Link } from '@material-ui/core'
import colors from './colors'
import theme from './theme'

const boldCaps = {
  fontWeight: 'bold',
  textTransform: 'uppercase'
}

const H2 = (props) => {
  const { children, ...other } = props
  return (
    <Typography variant="h2" {...other}>
      {children}
    </Typography>
  )
}
const H3 = (props) => {
  const { children, ...other } = props
  return (
    <Typography variant="h3" {...other}>
      {children}
    </Typography>
  )
}

export const TitleH1Bold = withStyles({
  root: {
    fontFamily: 'HeronSansCond, Sans-serif',
    fontSize: '13rem',
    textTransform: 'uppercase',
    lineHeight: 1.2
  }
})(Typography)

export const SectionHeader = withStyles({
  root: {
    ...boldCaps,
    fontSize: '1rem',
    letterSpacing: '2px'
  }
})(Typography)

export const SectionHeaderSmall = withStyles({
  root: {
    ...boldCaps,
    fontSize: '.875rem',
    letterSpacing: '1.5px'
  }
})(Typography)

export const TitleBold = withStyles({
  root: {
    ...boldCaps,
    fontSize: '1.5rem'
  }
})(Typography)

export const TitleSmall = withStyles({
  root: {
    fontSize: '.75rem',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    opacity: 0.6
  }
})(Typography)

export const TextSmall = withStyles({
  root: {
    fontSize: '.825rem',
    letterSpacing: '0.5px',
    opacity: 0.6
  }
})(Typography)

export const TitleH2Bold = withStyles({
  root: {
    fontFamily: 'HeronSansCond, Sans-serif',
    fontSize: '2.75rem',
    textTransform: 'uppercase',
    lineHeight: 1
  }
})(H2)
export const TitleH2BoldLarge = withStyles({
  root: {
    fontFamily: 'HeronSansCond, Sans-serif',
    fontSize: '3.75rem',
    textTransform: 'uppercase',
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem'
    }
  }
})(H2)

export const TitleH2BoldMenu = withStyles({
  root: {
    fontFamily: 'HeronSansCond, Sans-serif',
    fontSize: '2.625rem',
    textTransform: 'uppercase',
    lineHeight: '2.6875rem',
    letterSpacing: 2
  }
})(H2)

export const TitleH3Bold = withStyles({
  root: {
    fontFamily: 'HeronSansCond, Sans-serif',
    fontSize: '2.0625rem',
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    lineHeight: 1.2
  }
})(H3)

export const TitleH4Bold = withStyles({
  root: {
    fontFamily: 'HeronSansCond, Sans-serif',
    fontSize: '1.8255rem',
    textTransform: 'uppercase',
    letterSpacing: '1.36px',
    lineHeight: 1.18
  }
})(Typography)

export const TitleMedium = withStyles({
  root: {
    fontSize: '1.25rem',
    fontWeight: 400
  }
})(Typography)

export const TitleMediumBold = withStyles({
  root: {
    fontSize: '1.25rem',
    fontWeight: 500
  }
})(Typography)
export const TitleMediumLight = withStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 300,
    '@media (min-width: 600px)': {
      fontSize: '1.5rem'
    }
  }
})(Typography)

export const Body1 = withStyles({
  root: {
    fontSize: '1rem',
    lineHeight: '2.25'
  }
})(Typography)

export const BodySmall = withStyles({
  root: {
    fontSize: '.8125rem',
    letterSpacing: '.5px'
  }
})(Typography)

export const Body2 = withStyles({
  root: {
    fontSize: '0.875rem',
    lineHeight: '1.43',
    opacity: 0.6
  }
})(Typography)

export const Body3 = withStyles({
  root: {
    fontSize: '1.125rem',
    lineHeight: '2'
  }
})(Typography)

export const LinkUnderline = withStyles({
  root: {
    color: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    backgroundImage: `linear-gradient(transparent, transparent), linear-gradient(rgba(42, 158, 194, .3),rgba(42, 158, 194, .3)), linear-gradient( ${colors.blue1}, ${colors.blue1})`,
    backgroundSize: ' 20px 1px, 100% 1px, 0 1px',
    backgroundPosition: 'calc(20px * -1) 100%, 100% 100%, 0 100%',
    backgroundRepeat: ' no-repeat',
    transition: 'background-size .3s linear, background-position .3s linear',

    '&:visited': {
      backgroundImage: 'linear-gradient(#691f84, #691f84)'
    },

    '&:hover': {
      color: colors.blue1,
      textDecoration: 'none !important',
      backgroundSize: '20px 1px, 0 1px, 100% 1px',
      backgroundPosition: 'calc(100% + 20px) 100%, 100% 100%, 0 100%'
    }
  }
})(Link)

export const LinkUnderlineOnHover = withStyles({
  root: {
    color: 'inherit',
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'inline-block',
    backgroundImage:
      'linear-gradient(transparent, transparent), linear-gradient(transparent, transparent),linear-gradient(transparent, transparent)',
    backgroundSize: ' 20px 1px, 100% 1px, 0 1px',
    backgroundPosition: 'calc(20px * -1) 100%, 100% 100%, 0 100%',
    backgroundRepeat: ' no-repeat',
    transition: 'background-size .3s linear, background-position .3s linear',

    '&:visited': {
      backgroundImage: 'linear-gradient(#691f84, #691f84)'
    },

    '&:hover': {
      textDecoration: 'none !important',
      backgroundImage: `linear-gradient(transparent, transparent), linear-gradient(rgba(42, 158, 194, .3),rgba(42, 158, 194, .3)), linear-gradient( ${colors.blue1}, ${colors.blue1})`,
      backgroundSize: '20px 1px, 0 1px, 100% 1px',
      backgroundPosition: 'calc(100% + 20px) 100%, 100% 100%, 0 100%'
    }
  }
})(Link)
